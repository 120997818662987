body {
    font-family: 'Lato', 'Helvetica Neue', 'Helvetica','sans-serif';
}

#root {
}
#logo {
  display: block;
  margin: 0 auto;
  height: 100px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', 'Helvetica Neue', 'Helvetica','sans-serif';
  font-weight: 300;
}

header h1 {
  font-size: 2.5em;
  text-align: center;
  margin-top: 0px;
  font-weight: 800;
  text-transform: uppercase;
}
#text {
  max-width: 360px;
}

footer {
}
footer a {
  color: #707070;
  text-decoration: none;
}
